import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./components/common/Navbar/Navbar";
import Login from "./components/common/Auth/Login";
import Register from "./components/common/Auth/Register";

import AuthProvider from "./components/context/AuthProvider";
import ProtectedRoutes from "./components/utils/ProtectedRoutes";

import DataProvider from "./components/context/DataProvider";

import AdminIndex from "./components/dashboard/Admin/Index";
import ClientIndex from "./components/dashboard/Client/Index";
import ForgotPassword from "./components/common/Auth/ForgotPassword";

import CreatePost from "./components/dashboard/Admin/Posts/CreatePost";
import ListPosts from "./components/dashboard/Admin/Posts/ListPosts";

import CreateLiveEvent from "./components/dashboard/Admin/Events/CreateLiveEvent";
import EventsHistory from "./components/dashboard/Admin/Events/EventsHistory";

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <AuthProvider>
          <DataProvider>
            <Navbar />
            <Routes>
              <Route path="/" element={<h1>Home</h1>} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />

              <Route element={<ProtectedRoutes allowedRoles={["client"]} />}>
                <Route path="/dashboard" element={<ClientIndex />} />
              </Route>
              <Route element={<ProtectedRoutes allowedRoles={["admin"]} />}>
                <Route path="/admin" element={<AdminIndex />} />

                <Route path="/admin/posts" element={<ListPosts />} />
                <Route path="/admin/posts/create" element={<CreatePost />} />

                <Route path="/admin/events" element={<EventsHistory />} />
                <Route
                  path="/admin/create-event"
                  element={<CreateLiveEvent />}
                />
              </Route>

              <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
          </DataProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
