import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

// Use environment variables for production
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || "dev@123123";
const AUTH_API_BASE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8081/v1/auth"
    : process.env.REACT_APP_AUTH_API_BASE + "/auth";

console.log("AUTH_API_BASE", AUTH_API_BASE);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUserSession] = useState(null);
  const [role, setRole] = useState(null);

  const navigate = useNavigate();

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  };

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${AUTH_API_BASE}/login`, {
        email,
        password,
      });
      const { token, user } = response.data.data;

      setUserSession(user);
      setCurrentUser(user);
      setRole(user.role);

      sessionStorage.setItem("authToken", token);
      sessionStorage.setItem("userSession", encryptData(user));
      sessionStorage.setItem("role", encryptData(user.role));
      return user;
    } catch (error) {
      handleError(
        error,
        "Login failed. Please check your credentials and try again."
      );
    }
  };

  const signup = async (name, email, password, phone) => {
    try {
      const response = await axios.post(`${AUTH_API_BASE}/register`, {
        name,
        email,
        password,
        phone,
      });
      return response.data;
    } catch (error) {
      handleError(error, "Signup failed. Please try again.");
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await axios.post(
        `${AUTH_API_BASE}/validateToken`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, "Token validation failed. Please log in again.");
      return null;
    }
  };

  const logout = () => {
    sessionStorage.clear();
    setCurrentUser(null);
    setRole(null);
    navigate("/login");
  };

  const isAuthenticated = () => !!currentUser;

  const handleError = (error, defaultMessage) => {
    console.error("Error:", error);
    const message = error.response?.data?.message || defaultMessage;
    toast.error(message);
  };

  const navigateBasedOnRole = (userRole) => {
    if (userRole === "admin") {
      navigate("/admin");
    } else if (userRole === "user") {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = sessionStorage.getItem("authToken");
      const storedUserSession = sessionStorage.getItem("userSession");
      const storedRole = sessionStorage.getItem("role");

      if (storedUserSession && storedRole) {
        try {
          const decryptedUserSession = decryptData(storedUserSession);
          const decryptedRole = decryptData(storedRole);
          setCurrentUser(decryptedUserSession);
          setRole(decryptedRole);
        } catch (error) {
          console.error("Decryption error:", error);
          logout();
        }
      }

      if (token) {
        const res = await validateToken(token);
        if (
          res &&
          res.status === "success" &&
          res.code === 200 &&
          res.data.loggedIn
        ) {
          if (res.data.user.exp > Date.now() / 1000) {
            navigateBasedOnRole(res.data.user.role);
          } else {
            toast.error("Session expired. Please login again.");
            logout();
          }
        } else {
          logout();
        }
      }

      setLoading(false);
    };

    checkAuth();
  }, []);

  const authContextValue = {
    currentUser,
    login,
    logout,
    isAuthenticated,
    loading,
    signup,
    role,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
