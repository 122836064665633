import React, { useState } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useData } from "../../../context/DataProvider";

export default function CreatePost() {
  const { createPost, loading, error } = useData(); // Get loading and error from context
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    imageUrl: "",
    isPage: false,
    tags: "",
    seo: {
      title: "",
      description: "",
      keywords: "",
    },
  });

  const [seoEnabled, setSeoEnabled] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith("seo.")) {
      setFormData({
        ...formData,
        seo: {
          ...formData.seo,
          [name.split(".")[1]]: value,
        },
      });
    } else if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleContentChange = (value) => {
    setFormData({
      ...formData,
      content: value,
    });
  };

  const handleSeoDescriptionChange = (value) => {
    setFormData({
      ...formData,
      seo: {
        ...formData.seo,
        description: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title || !formData.content) {
      toast.error("Title and content are required.");
      return;
    }

    const result = await createPost({
      ...formData,
      tags: formData.tags.split(",").map((tag) => tag.trim()),
    });

    if (result) {
      setFormData({
        title: "",
        content: "",
        imageUrl: "",
        isPage: false,
        tags: "",
        seo: {
          title: "",
          description: "",
          keywords: "",
        },
      });
    }
  };

  return (
    <div className="container">
      <h2>Create Post</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">Error: {error}</p>}
      <form onSubmit={handleSubmit}>
        {/* Title */}
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            className="form-control"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>

        {/* Content */}
        <div className="mb-3">
          <label htmlFor="content" className="form-label">
            Content
          </label>
          <ReactQuill
            id="content"
            name="content"
            theme="snow"
            value={formData.content}
            onChange={handleContentChange}
            required
            style={{ height: "300px" }}
          />
        </div>

        {/* Image URL and Tags */}
        <div className="mb-3 mt-5 row">
          <div className="col-md-6">
            <label htmlFor="imageUrl" className="form-label">
              Thumbnail Image
            </label>
            <input
              type="file"
              id="imageUrl"
              name="imageUrl"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="tags" className="form-label">
              Tags (comma-separated)
            </label>
            <input
              type="text"
              id="tags"
              name="tags"
              className="form-control"
              value={formData.tags}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* SEO and Page Options */}
        <div className="mb-3 row">
          <div className="col-md-6 form-check">
            <input
              type="checkbox"
              id="seoEnabled"
              className="form-check-input"
              checked={seoEnabled}
              onChange={() => setSeoEnabled(!seoEnabled)}
            />
            <label htmlFor="seoEnabled" className="form-check-label">
              Enable SEO Options
            </label>
          </div>
          <div className="col-md-6 form-check">
            <input
              type="checkbox"
              id="isPage"
              name="isPage"
              className="form-check-input"
              checked={formData.isPage}
              onChange={handleChange}
            />
            <label htmlFor="isPage" className="form-check-label">
              Is this a Page?
            </label>
          </div>
        </div>

        {seoEnabled && (
          <div className="seo-options mb-3">
            <h4>SEO Options</h4>

            {/* SEO Title */}
            <div className="mb-3">
              <label htmlFor="seoTitle" className="form-label">
                SEO Title
              </label>
              <input
                type="text"
                id="seoTitle"
                name="seo.title"
                className="form-control"
                value={formData.seo.title}
                onChange={handleChange}
              />
            </div>

            {/* SEO Description */}
            <div className="mb-3">
              <label htmlFor="seoDescription" className="form-label">
                SEO Description
              </label>
              <ReactQuill
                id="seoDescription"
                name="seo.description"
                value={formData.seo.description}
                onChange={handleSeoDescriptionChange}
                style={{ height: "300px" }}
              />
            </div>

            {/* SEO Keywords */}
            <div className="mb-3 mt-5">
              <label htmlFor="seoKeywords" className="form-label">
                SEO Keywords (comma-separated)
              </label>
              <input
                type="text"
                id="seoKeywords"
                name="seo.keywords"
                className="form-control"
                value={formData.seo.keywords}
                onChange={handleChange}
              />
            </div>
          </div>
        )}

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Submitting..." : "Create Post"}
        </button>
      </form>
    </div>
  );
}
