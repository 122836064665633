import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

const APP_API_BASE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8082/v1/posts"
    : `${process.env.REACT_APP_API_BASE}/posts`;

console.log("APP_API_BASE", APP_API_BASE);

const DataProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);

  const createPost = async (postData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(APP_API_BASE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) throw new Error("Failed to create post");

      const newPost = await response.json();
      setPosts((prevPosts) => [...prevPosts, newPost]);
      return newPost;
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataContext.Provider value={{ createPost, loading, error, posts }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
