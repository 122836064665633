import React, { useState } from 'react';
import './ForgotPassword.css'; // Create a separate CSS file for styling
import { useAuth } from '../../context/AuthProvider';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const { sendPasswordReset } = useAuth(); // Assume this function is defined in your AuthProvider

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      sendPasswordReset(email);
      // Optionally show a success message or redirect
    }
  };

  return (
    <div className="forgot-password-container">
      <h2 className="forgot-password-title">Reset Your Password</h2>
      <hr />
      <form className="forgot-password-form mt-4" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <button type="submit" className="btn btn-primary w-100">Send Reset Link</button>
      </form>
      <p className="mt-3 text-center">
        Remembered your password? <a href="/login">Login</a>
      </p>
    </div>
  );
}
