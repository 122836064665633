import React, { useState } from "react";
import { useAuth } from "../../context/AuthProvider";
import "./Navbar.css";
import menuData from "./menu.json";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, logout } = useAuth();

  const toggleCanvas = () => {
    setIsOpen(!isOpen);
  };

  const getMenuItems = () => {
    if (!currentUser) {
      return menuData.default;
    }
    return currentUser.role === "admin" ? menuData.admin : menuData.user;
  };

  const handleLogout = () => {
    logout();
  };

  // State for managing nested dropdowns
  const [openDropdowns, setOpenDropdowns] = useState({});

  const toggleDropdown = (index) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific dropdown
    }));
  };

  const renderMenuItems = (items) => {
    return items.map((item, index) => (
      <li
        key={index}
        className={`nav-item ${item.children.length ? "dropdown" : ""}`}
      >
        <Link
          className={`nav-link ${
            item.children.length ? "dropdown-toggle" : ""
          }`}
          to={item.link}
          onClick={
            item.children.length
              ? (e) => {
                  e.preventDefault();
                  toggleDropdown(index);
                }
              : undefined
          }
        >
          {item.name}
        </Link>
        {item.children.length > 0 && (
          <ul className={`dropdown-menu ${openDropdowns[index] ? "show" : ""}`}>
            {renderMenuItems(item.children)}
          </ul>
        )}
      </li>
    ));
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <span className="brand-title">Shri Yoganand Maharaj</span>
            <br />
            <span className="brand-subtitle">Sansthan Gunj</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCanvas}
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              {renderMenuItems(getMenuItems())}
              {currentUser && (
                <li className="nav-item">
                  <button
                    className="nav-link btn btn-link"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <div className={`canvas-menu ${isOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={toggleCanvas}>
          X
        </button>
        <ul className="canvas-menu-list">
          {renderMenuItems(getMenuItems())}
          {currentUser && (
            <li>
              <button className="nav-link btn btn-link" onClick={handleLogout}>
                Logout
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
