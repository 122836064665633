import React from "react";
import "./Index.css";

export default function AdminIndex() {
  return (
    <div className="container-fluid mt-3 mb-4">
      <h2>admin</h2>
    </div>
  );
}
