import React, { useState } from 'react';
import './Login.css';
import { useAuth } from '../../context/AuthProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Loading state

  const navigate = useNavigate();
  const { login } = useAuth();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate input fields
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);

    // If there are no validation errors, proceed with login
    if (Object.keys(newErrors).length === 0) {
      setLoading(true); // Set loading state
      try {
        const user = await login(email, password);

        if (user) {
          // Redirect based on user role
          if (user.role === "admin") {
            navigate("/admin");
            toast.success("Welcome back, Admin!");
          } else if (user.role === "user") {
            navigate("/dashboard");
            toast.success("Welcome back, User!");
          } else {
            toast.error("Unexpected role. Please contact support.");
            navigate("/");
          }
        }
      } catch (error) {
        toast.error("Login failed. Please check your credentials and try again.");
      } finally {
        setLoading(false); // Reset loading state
      }
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Login to Your Account</h2>
      <hr />
      <form className="login-form mt-4" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-invalid={errors.email ? 'true' : 'false'}
          />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-invalid={errors.password ? 'true' : 'false'}
          />
          {errors.password && <div className="invalid-feedback">{errors.password}</div>}
        </div>
        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      <p className="mt-3 text-center">
        Forgot your password? <a href="/forgot-password">Click Here</a>
      </p>
      <p className="mt-1 text-center">
        Don't have an account? <a href="/register">Register</a>
      </p>
      {/* Live region for screen readers */}
      <div aria-live="polite" aria-hidden="true">
        {errors.email && <span>{errors.email}</span>}
        {errors.password && <span>{errors.password}</span>}
      </div>
    </div>
  );
}
